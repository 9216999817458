import { SearchOff } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import cx from 'classnames';
import { startTransition, useEffect, useRef, useState, type KeyboardEvent } from 'react';
import { Link } from 'wouter';
import { alignCenter, flex, hidden, show100 } from '~/app.css';
import Icon from '~/elements/Icon/Icon';
import { Ctrl, Search as SearchIcon } from '~/elements/icons';
import { colors } from '~/theme.css';
import { type DriveFile } from '~/utils/DriveAPI';
import { LEGACY, isMobile, mimeTypes, platform } from '~/utils/constants';
// import gup from '~/utils/gup';
import { useKeyPress, useSearch } from '~/utils/hooks';
import { isMarkdownLink } from '~/utils/isMarkdownLink';
import { getRange, getTextFromFile, rem } from '~/utils/utils';
import * as styles from './Search.css';
import { focused, keyboardShortcut } from './Search.css';

// const searchQuery = gup('q') || gup('s');

const SearchResult = ({
  file,
  input,
  wiki,
  onKeyDown,
}: {
  file: DriveFile & { html?: string };
  input: string;
  wiki: DriveFile;
  onKeyDown?: (e: KeyboardEvent) => void;
}) => {
  const externalLink = isMarkdownLink(file.name);
  const name = externalLink ? externalLink.name : file.name;

  const content = externalLink ? '' : getTextFromFile(file, false);
  const { textBefore, match, textAfter, title, titleBefore, titleAfter, nameIndex } = getRange({
    input,
    name,
    content,
  });

  const legacyTo = LEGACY ? `/app/page/${file.id}?p=${wiki.id}` : `/app/page/${file.id}`;

  return (
    <MenuItem
      key={file.id}
      className={styles.menuItem}
      focusVisibleClassName="focus-visible"
      component={Link}
      to={externalLink ? externalLink.url : LEGACY ? legacyTo : `/app/page/${wiki.id}/${file.id}`}
      {...(externalLink && { target: '_blank' })}
      {...(onKeyDown && { onKeyDown })}
      {...(LEGACY && {
        onClick: () => {
          if (!externalLink) {
            window.legacyHistory?.push(legacyTo);
          }
        },
      })}
    >
      <div
        className={styles.searchResult}
        style={{
          gap: rem(12),
        }}
      >
        <Icon file={file} externalLink={externalLink} />
        <div>
          {nameIndex === -1 ? (
            <div>{name}</div>
          ) : (
            <div>
              {titleBefore}
              <mark className={styles.mark} data-testid="mark">
                {title}
              </mark>
              {titleAfter}
            </div>
          )}
          {file.html && match && (
            <div
              style={{
                fontSize: '0.875rem',
                lineHeight: '1.28rem',
              }}
            >
              {textBefore}
              <mark className={styles.mark} data-testid="mark">
                {match}
              </mark>
              {textAfter}
            </div>
          )}
        </div>
      </div>
    </MenuItem>
  );
};

const Search = ({
  files,
  wiki,
  defaultMaxResults = 100,
}: { files: DriveFile[]; wiki: DriveFile; defaultMaxResults?: number }) => {
  const [input, setInputValue] = useState(''); // controlled input
  const [deferredInput, setDeferredInput] = useState(''); // deferred input (search results)
  const [isFocused, setIsFocused] = useState(false);
  const [preventFocus, setPreventFocus] = useState(false);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [maxResults, setMaxResults] = useState(defaultMaxResults);
  const setInput = (input: string) => {
    setInputValue(input);
    startTransition(() => setDeferredInput(input));
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);

  const { data: _data, isLoading } = useSearch(deferredInput, wiki, files);
  const data = _data.filter((file) => file.name !== 'wiki.logo' && file.mimeType !== mimeTypes.png);
  const results = data.length > maxResults ? data.slice(0, maxResults) : data;
  const open = input.length > 0 && deferredInput.length > 0 && Boolean(anchorEl);

  useKeyPress('k', (e) => {
    if (e.metaKey || e.ctrlKey) {
      e.preventDefault();
      inputRef.current?.focus();
    }
  });

  // useEffect(() => {
  //   if (searchQuery && typeof searchQuery === 'string') {
  //     setInput(searchQuery);
  //     setIsFocused(true);
  //     setTimeout(() => {
  //       setAnchorEl(searchRef.current);
  //     }, 200);
  //   }
  // }, []);

  // Legacy support for custom domains using iframe, remove once moved away from iframe
  // Listen for postMessage('focusSearch', '*');
  useEffect(() => {
    if (!LEGACY) return;
    const fn = (e: MessageEvent) => {
      if (e.data === 'focusSearch') {
        inputRef.current?.focus();
      }
    };
    window.addEventListener('message', fn);
    return () => window.removeEventListener('message', fn);
  }, []);

  // Result heights: 36 (no content), 56.5 (content)
  // Maximum height is 14 entries
  // Find how many files will fit in the menu when they can be no content or content
  const innerHeight = window.innerHeight;
  const maxHeight = open
    ? data.slice(0, 14).reduce((acc, file) => {
        const { match } = getRange({
          input: deferredInput,
          name: file.name,
          content: getTextFromFile(file),
        });
        const result = acc + (match ? 56.5 : isMobile ? 48 : 36);
        if (result > innerHeight - (isMobile ? 150 : 100)) return acc;
        return result;
      }, 0)
    : 0;

  return (
    <div className={styles.container}>
      {/* Search input */}
      <div className={styles.containerInner}>
        <div
          ref={searchRef}
          className={cx(styles.search, { [focused]: isFocused })}
          style={{
            zIndex: open ? 1301 : 2, // 1300 is the material ui modal
          }}
        >
          <input
            className={styles.searchInput}
            autoComplete="off"
            value={input}
            ref={inputRef}
            name="search"
            role="searchbox"
            type="text"
            placeholder="Search"
            onFocus={(e) => {
              // When the menu is closed, the input is focused again automatically
              // This prevents it from happening
              if (preventFocus) {
                e.currentTarget.blur();
                setPreventFocus(false);
                return;
              }
              setIsFocused(true);
              if (deferredInput) {
                setAnchorEl(searchRef.current);
              }
            }}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => {
              const input = e.currentTarget.value;
              setInput(input);
              if (anchorEl && input.length === 0 && maxResults !== defaultMaxResults) {
                setMaxResults(defaultMaxResults);
              }
              if (!anchorEl && input.length > 0) setAnchorEl(searchRef.current);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                if (open) {
                  handleClose();
                } else {
                  e.currentTarget.blur();
                }
                return;
              }

              // ArrowDown
              if (e.key === 'ArrowDown') {
                e.preventDefault();
                const firstMenuItem = document.querySelector('[role="menuitem"]:first-child');
                if (firstMenuItem instanceof HTMLElement) {
                  firstMenuItem.focus();
                }
                return;
              }

              // ArrowUp
              if (e.key === 'ArrowUp') {
                e.preventDefault();
                const lastMenuItem = document.querySelector('[role="menuitem"]:last-child');
                if (lastMenuItem instanceof HTMLElement) {
                  lastMenuItem.focus();
                }
                return;
              }
              if (e.key === 'Enter' && open) {
                e.preventDefault();
                const firstMenuItem = document.querySelector('[role="menuitem"]:first-child');
                if (firstMenuItem instanceof HTMLElement) {
                  firstMenuItem.click();
                }
                e.currentTarget.blur();
                return;
              }
            }}
          />

          {!isMobile && (
            <div className={cx([keyboardShortcut, { [hidden]: isFocused || open || isLoading }])}>
              {platform === 'macOS' ? <div className={styles.cmdKey}>⌘</div> : <Ctrl className={styles.ctrlKey} />}
              <div
                style={{
                  fontSize: platform === 'macOS' ? 'inherit' : 12,
                  marginTop: platform === 'macOS' ? undefined : -0.5,
                  marginLeft: platform === 'macOS' ? undefined : -0.5,
                  fontFamily: platform === 'macOS' ? undefined : 'Montserrat',
                  color: platform === 'macOS' ? undefined : '#BDBDBE',
                }}
                className={styles.kKey}
              >
                K
              </div>
            </div>
          )}
          {isLoading && (
            <div
              className={cx([flex, alignCenter, show100])}
              style={{
                position: 'absolute',
                right: rem(16),
              }}
            >
              <CircularProgress
                aria-describedby="searchbox"
                aria-label="Searching..."
                size={16}
                style={{
                  display: 'flex',
                  color: '#ccc',
                }}
              />
            </div>
          )}
        </div>
        <button
          aria-label="Search"
          className={styles.searchButton}
          type="button"
          onClick={() => inputRef?.current?.focus()}
        >
          <SearchIcon htmlColor="#7C7C7C" />
        </button>
      </div>
      <div ref={containerRef} />
      <Menu
        container={containerRef.current}
        disablePortal
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        transitionDuration={0}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        onKeyDown={(e) => {
          // Handle select all
          const selectAll = (e.metaKey || e.ctrlKey) && e.key === 'a';
          if (selectAll) {
            setIsFocused(true);
            inputRef.current?.focus();
            inputRef.current?.select();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          // If it's a meta key (navigation) proceed
          if (e.metaKey || e.ctrlKey) {
            // cmd+r
            if (e.key === 'r' || e.key === 'R') {
              e.preventDefault();
              window.location.reload();
              handleClose();
            }
            return;
          }
          // If word character is pressed, focus input
          if ((/\w|\s/.test(e.key) && e.key.length === 1) || e.key === 'Backspace') {
            setIsFocused(true);
            handleClose();
            if (e.key.length === 1) {
              setInput(deferredInput + e.key);
              e.preventDefault();
              e.stopPropagation();
            }
          }
        }}
        onClick={(e) => {
          const { target } = e;
          // When clicking on a menu item, reset the input and close the menu
          setPreventFocus(true);
          handleClose();
          setTimeout(() => setPreventFocus(false));

          // Keep input if clicking on backdrop
          const hidden =
            target instanceof HTMLDivElement &&
            (target.ariaHidden === 'true' || target.getAttribute('aria-hidden') === 'true');
          if (hidden) return;

          setInput('');
          setMaxResults(defaultMaxResults);
        }}
        // I'm not sure why but material-ui will continue to render the menu even if open is false
        // It renders it as aria-hidden="true" but it can still overlay
        style={{
          display: open ? 'initial' : 'none',
        }}
        slotProps={{
          paper: {
            style: {
              // 16 padding + 2.5 top and bottom padding
              maxHeight: maxHeight ? maxHeight + 21 : undefined,
              paddingTop: '.1rem',
              paddingBottom: '.1rem',
              border: '1px solid #ccc',
              boxShadow: colors.search.menuBoxShadow,
              borderRadius: 12,
              minWidth: 400 - 64,
              marginTop: 3,
            },
          },
        }}
      >
        {open &&
          results.map((file, i) => (
            <SearchResult
              key={file.id}
              file={file}
              wiki={wiki}
              input={deferredInput}
              // Closing the menu returns focus to the input
              // First entry
              {...(i === 0 && {
                onKeyDown: (e: KeyboardEvent) => {
                  if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                  }
                },
              })}
              // Last entry
              {...(i === data.length - 1 && {
                onKeyDown: (e: KeyboardEvent) => {
                  if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    handleClose();
                  }
                },
              })}
              // Single entry
              {...(data.length === 1 && {
                onKeyDown: (e: KeyboardEvent) => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault();
                    handleClose();
                  }
                },
              })}
            />
          ))}
        {open && data.length > maxResults && (
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setMaxResults(maxResults + defaultMaxResults);
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              className={styles.searchButton}
              style={{
                width: 'auto',
                padding: '1rem',
                borderRadius: 40,
                color: '#555',
              }}
            >
              Show more results
            </button>
          </MenuItem>
        )}
        {isLoading && data.length === 0 && (
          <MenuItem
            disabled
            style={{
              display: 'flex',
              gap: '.5rem',
            }}
          >
            Loading results...
          </MenuItem>
        )}
        {!isLoading && data.length === 0 && (
          <MenuItem
            disabled
            style={{
              display: 'flex',
              gap: '.5rem',
              opacity: 0.6,
            }}
          >
            <SearchOff /> No results for &quot;{deferredInput}&quot;
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Search;

// /* eslint-disable no-console */
// export default memo(Search, (prev, next) => {
//   if (prev.files.length !== next.files.length) return false;
//   if (prev.wiki.id !== next.wiki.id) return false;

//   // If length and first and last entry are the same, assume the rest are the same
//   if (prev.files.at(0)?.id !== next.files.at(0)?.id) return false;
//   if (prev.files.at(-1)?.id !== next.files.at(-1)?.id) return false;

//   // Check if the files are the same using every (development testing only)
//   if (DEV) {
//     const same = prev.files.every((file) => file.id === next.files.find((f) => f.id === file.id)?.id);
//     if (!same) {
//       console.error('Search files are not the same');
//       console.log(prev.files, next.files);
//       return false;
//     }
//   }

//   return true;
// });
// /* eslint-enable no-console */
