import { CircularProgress } from '@mui/material';
import cx from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { useSWRConfig } from 'swr';
import { Link, useLocation, useRoute } from 'wouter';
import { colors, darkTheme, lightTheme } from '~/theme.css';
import * as appStyles from './app.css';
import LastModified from './components/LastModified/LastModified';
import { Page } from './components/Page/Page';
import * as pageStyles from './components/Page/Page.css';
import PrevNext from './components/PrevNext/PrevNext';
import Search from './components/Search/Search';
import Tree, { StaticTree } from './components/Tree/Tree';
import Button from './components/WikiSelector/WikiSelector';
import Breadcrumbs from './elements/Breadcrumbs/Breadcrumbs';
import { CheckboxMacro } from './elements/CheckboxMacro';
import { SkeletonMacro } from './elements/SkeletonMacro';
import Title from './elements/Title/Title';
import { TreeMacro } from './elements/TreeMacro';
import { DEV, newFileId, preview } from './utils/constants';
import {
  useAuth,
  useExport,
  useFile,
  useFiles,
  useFolder,
  useKeyPress,
  useLeadingDebounce,
  useTheme,
  useWiki,
  useWikiList,
} from './utils/hooks';
import { isMarkdownLink } from './utils/isMarkdownLink';
import { log } from './utils/logger';
import { parseExport } from './utils/parseExport';
import { getId, getMimeTypeNameFromFile, isADocument, isAFile, isAFolder, ls, macros } from './utils/utils';

function App() {
  const { mutate } = useSWRConfig();
  const params = useRoute('/app/page/:parent/:child?')[1];
  const parent = params?.parent ?? ls.get<{ id: string }>('wiki')?.id ?? undefined;
  const theme = useTheme();
  const { user } = useAuth();

  // YNAW folder
  const { data: folder } = useFolder();

  // Wiki list
  const { data: wikis, isLoading: wikisLoading } = useWikiList(folder?.id);

  // Wiki
  const { data: _wiki, isLoading: isLoadingWiki } = useWiki(parent, wikis, folder?.id);
  // if there's a wiki, use that
  // if there's a wiki in the wiki list, use that
  // if loading, return undefined
  const wiki = _wiki ?? wikis?.find((o) => o.id === parent) ?? (isLoadingWiki ? _wiki : wikis?.[0]);
  useEffect(() => wiki && log.info('wiki', wiki), [wiki]);

  // Files
  const { data: _files, isLoading: isLoadingFiles } = useFiles(wiki?.id);
  const searchFiles = _files.filter((file) => file.name !== 'wiki.logo');
  const files = _files.filter((file) => file.name !== 'wiki.page' && file.name !== 'wiki.logo') ?? undefined;

  // const child = params?.child ?? parent ?? wiki?.id;

  const child =
    params?.child === 'new'
      ? files.some((o) => o.id === 'new')
        ? params?.child
        : files.toSorted((a, b) => (new Date(a.createdTime).getTime() > new Date(b.createdTime).getTime() ? -1 : 1))[0]
            ?.id
      : params?.child ?? parent ?? wiki?.id;
  // console.log(
  //   'files',
  //   files.map((o) => ({ id: o.id, name: o.name, mimeType: o.mimeType }))
  // );
  // console.log('files', { _files: _files.length, files: files.length, isLoadingFiles });

  // Debugging
  // console.log(
  //   'files',
  //   files
  //     // .filter((o) => o.parents?.[0] === '16ysq_bBjlI_h6-yc7SxzyL5Swaj4xiU1')
  //     .filter((o) => o.parents?.[0] === '1N_-IfZ_w6LD0P5BT8NIcuMKwZiPCCZ8R')
  //     .map((o) => ({ name: o.name, ...(o.properties?.index && { properties: { index: o.properties?.index } }) }))
  // );

  // console.log('user', user);
  // File
  const { data: file } = useFile(child, files);

  // Debugging
  const fileRef = useRef<string | undefined>(undefined);
  useEffect(() => {
    if (fileRef.current === file?.id) return;
    fileRef.current = file?.id;
    log.info('file', file);
  }, [file]);

  // Debugging
  // useEffect(() => {
  //   console.log('file', file);
  // }, [file?.id]);

  // Test expired token after some time period
  // useSimulateExpiredToken();

  // Files export
  const indexPage = isAFolder(file)
    ? _files?.find((o) => file && o.parents?.[0] === file.id && o.name === 'wiki.page')
    : undefined;

  const { data: html, isLoading: isLoadingContent } = useExport(indexPage ?? file);

  // Keyboard support for edit and view buttons
  const editButtonRef = useRef<HTMLAnchorElement>(null);
  const viewButtonRef = useRef<HTMLAnchorElement>(null);

  // Content
  const { content } = html ? parseExport({ html, file, files, wiki }) : { content: '' };
  // const text = getTextFromHTML(content);

  // Folder children
  const children = isAFolder(file) ? files.filter((child) => file && child.parents?.[0] === getId(file)) : null;

  // Save current wiki to local storage
  useEffect(() => {
    if (!parent) return;
    ls.set<{ id: string }>('wiki', { id: parent });
  }, [parent]);

  const navigate = useLocation()[1];

  // Handle link clicks to pages that are within the same wiki
  // Intercept and navigate to the page
  const pageRef = useRef<HTMLDivElement>(null);
  const navigateToPage = useCallback(
    (e: MouseEvent) => {
      if (!(e.target instanceof HTMLAnchorElement)) return;
      const { target } = e;
      const href = target.getAttribute('href');
      if (target.tagName !== 'A' || !href?.startsWith('/app/page')) return true;
      e.preventDefault();
      if (href) {
        navigate(href);
      }
      return;
    },
    [navigate]
  );

  useEffect(() => {
    const page = pageRef.current;
    if (!page) return;
    page.addEventListener('click', navigateToPage);
    return () => {
      page.removeEventListener('click', navigateToPage);
    };
  }, [navigateToPage]);

  useKeyPress('e', () => {
    if (editButtonRef.current) {
      editButtonRef.current.click();
    }
  });

  // Debugging
  // biome-ignore format: disable-next-line
  useKeyPress('p', DEV ? () => preview ? window.open(file?.webViewLink.replace('edit', 'preview'), '_blank') : window.open(window.location.href + '?preview', '_blank') : undefined);

  // We use a fade animation when loading folders as we don't know if they have children
  // That animation flickers when moving folders in the tree, this disables that class once we know the wiki has loaded
  // If you can figure out why it's re-rendering, then this won't be needed
  const hasLoaded = useLeadingDebounce(!isLoadingFiles && files.length > 0, 500);

  // Metrics
  // useEffect(() => {
  //   if (user) {
  //     posthog.identify(user.id, {
  //       email: user.email,
  //       name: user.name,
  //     });
  //   }
  // }, [user]);

  // If the wiki is private and the user is not logged in, redirect to login
  const privateWiki = wiki?.permissions && !wiki?.permissions?.some((permission) => permission.type === 'anyone');
  if (privateWiki && user?.email === '') {
    return <Link to="/auth">Go to login</Link>;
  }

  const { color } = wiki?.properties ?? {};
  const markdown = file ? isMarkdownLink(file.name) : undefined;

  return (
    <div
      className={cx(appStyles.appContainer, {
        [darkTheme]: theme === 'dark',
        [lightTheme]: theme === 'light',
        preview,
        scrollbar: preview && document.body.scrollHeight > window.innerHeight,
      })}
    >
      {/* Sidebar */}
      <div
        className={appStyles.sidebar}
        style={{
          ...(wiki && {
            backgroundColor: color ?? '#638AF5',
          }),
        }}
      >
        {!wiki && wikisLoading && (
          <CircularProgress style={{ color: colors.spinner.default }} thickness={4} size={34} />
        )}
        {wiki && user && <Button wikis={wikis ?? []} wiki={wiki} user={user} />}
        {!wiki && isLoadingWiki && (
          <CircularProgress style={{ color: colors.spinner.default }} thickness={4} size={34} />
        )}

        {wiki && (
          <div
            style={{
              padding: '.5rem 1rem',
            }}
          >
            {isLoadingFiles && _files.length === 0 && (
              <CircularProgress style={{ color: colors.spinner.default }} thickness={4} size={34} />
            )}
            <div>
              <Tree wiki={wiki} files={files} currentFile={file} showHome user={user} hasLoaded={hasLoaded} />
            </div>
          </div>
        )}
      </div>
      <div className={appStyles.contentContainer}>
        <div className={appStyles.searchContainer}>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              maxWidth: 400,
            }}
          >
            <div
              className={appStyles.flex}
              style={{
                flexGrow: 1,
              }}
            >
              {wiki && <Search files={searchFiles} wiki={wiki} />}
            </div>
          </div>
        </div>
        <div className={appStyles.headerContainer}>
          <div style={{ display: 'flex', gap: '.5rem' }}>
            {(indexPage?.webViewLink || file?.webViewLink) && (
              <a ref={editButtonRef} target="_blank" href={indexPage?.webViewLink ?? file?.webViewLink}>
                Edit
              </a>
            )}
            {file?.webViewLink && <span style={{ opacity: 0.3 }}>|</span>}
            {file && (isAFolder(file) ? file.id : file?.parents?.[0]) && (
              <a
                ref={viewButtonRef}
                target="_blank"
                href={
                  isAFolder(file)
                    ? `https://drive.google.com/drive/folders/${file?.id}${user?.email ? `?authuser=${user?.email}` : ''}`
                    : `https://drive.google.com/drive/folders/${file?.parents?.[0]}${user?.email ? `?authuser=${user?.email}` : ''}`
                }
              >
                View in Drive
              </a>
            )}
            {file && <span style={{ opacity: 0.3 }}>|</span>}
            <Link
              to="/auth"
              onClick={async (e) => {
                e.preventDefault();
                // ls.remove('wiki');
                await fetch('/api/user/me/logout');
                await mutate('/api/user/me', undefined, true);
                navigate('/auth');
              }}
            >
              Sign out
            </Link>
          </div>
        </div>
        <div className={appStyles.pageContainer}>
          {file && wiki && <Breadcrumbs files={files} file={file} wiki={wiki} />}
          {file?.name && (
            <div className={pageStyles.titleContainer}>
              <Title className={pageStyles.title}>{markdown ? markdown.name : file.name}</Title>
            </div>
          )}
          {file && isAFile(file) && <LastModified file={file} content={content} />}

          {/* Spinner */}
          {((params?.child === newFileId && files.some((o) => o.id === newFileId)) ||
            (isLoadingContent && !content)) && (
            <CircularProgress style={{ color: colors.spinner.default }} thickness={4} size={34} />
          )}

          {/* Page */}
          {content && <Page content={content} file={file} pageRef={pageRef} indexPage={indexPage} />}

          {preview && (
            <a
              href={file?.webViewLink.replace('edit', 'preview')}
              target="_blank"
              className="document"
              style={{
                position: 'fixed',
                bottom: '.5rem',
                right: '.5rem',
              }}
            >
              <img
                style={{
                  width: 32,
                }}
                src="https://www.gstatic.com/images/branding/product/1x/docs_48dp.png"
                alt="Google Docs"
              />
            </a>
          )}

          {html && !content && isADocument(file) && (
            <div ref={pageRef} className={cx(pageStyles.page, getMimeTypeNameFromFile(file))}>
              <a href={file.webViewLink} target="_blank">
                Edit
              </a>
            </div>
          )}
          {/* Folder */}
          {file && children && wiki && !indexPage && (
            <div className={pageStyles.page}>
              <StaticTree wiki={wiki} files={children} currentParentId={getId(file)} depth={1} />
            </div>
          )}
          {/* TODO: refactor these to use a useMacro hook and set them up dynamically */}
          {/* Folder with page content */}
          {/* Tree Macro */}
          {indexPage && file && wiki && children && content && macros.files.test(content) && (
            <TreeMacro
              wiki={wiki}
              file={file}
              parent={wiki.id}
              files={children}
              content={content}
              query={`[data-react="${Tree.name}"]`}
            />
          )}
          {/* Skeleton loader macro */}
          {content.includes('data-react="SkeletonLoader') && (
            <SkeletonMacro content={content} query={`[data-react="SkeletonLoader"]`} />
          )}
          {file && macros.checkbox.test(content) && (
            <CheckboxMacro content={content} query={`[data-react="Checkbox"]`} id={file.id} />
          )}
          {wiki && file && file && <PrevNext file={file} files={files} wiki={wiki} />}
        </div>
      </div>
    </div>
  );
}

export default App;
