import { preload, type Cache } from 'swr';
import { serialize } from 'swr/_internal';
import { defaultScope, DEV, hasWindow, newFileId, type DriveMimeTypes } from '~/utils/constants';
import { exportFormats, isBun, mimeTypes } from './constants';
import * as DriveAPI from './DriveAPI';
import { type DriveFile } from './DriveAPI';
import { entityMap } from './entityMap';
import gup from './gup';
import { isMarkdownLink } from './isMarkdownLink';
import { log } from './logger';
import { type Profile } from './types';

// Allows intellisense, as for some reason tsc cannot find the types for @gkiely/utils
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// CSS
export const em = (px: number, size = 16) => `${px / size}em`;
export const rem = (px: number, size = 16) => `${px / size}rem`;
export const pc = (px: number, size = 16) => `${(px / size) * 100}%`;

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
// FIXME: this needs to be removed in favor of the one in sort.ts
export const sortArgsByName = (a: DriveFile, b: DriveFile) => collator.compare(a.name, b.name);
export const sortArrByName = (arr: DriveFile[]) => arr.sort((a, b) => collator.compare(a.name, b.name));

// Runtime only check
export function assertType<T>(_: unknown): asserts _ is T {}

export function invariant(condition: boolean, message?: string): asserts condition {
  if (DEV) {
    if (!condition) {
      throw new Error(`Assertion failed${message ? `: ${message}` : ''}`);
    }
    return;
  }

  if (!condition) {
    TrackJS.track(`Assertion failed${message ? `: ${message}` : ''}`);
  }
}

export function assert<T>(value: unknown, message?: string): asserts value is NonNullable<T> {
  if (DEV) {
    if (!value) {
      throw new Error(`Assertion failed${message ? `: ${message}` : ''}`);
    }
    return;
  }

  if (!value) {
    TrackJS.track(`Assertion failed${message ? `: ${message}` : ''}`);
  }
}

export const omit = <T extends object, K extends Extract<keyof T, string> = Extract<keyof T, string>>(
  obj: T,
  ...keys: K[]
): Omit<T, K> => {
  const result = {} as { [K in keyof typeof obj]: (typeof obj)[K] };
  for (const key in obj) {
    if (!keys.includes(key as K)) {
      result[key] = obj[key];
    }
  }
  return result;
};

export const pick = <T extends object, K extends Extract<keyof T, string> = Extract<keyof T, string>>(
  obj: T,
  ...keys: K[]
): Pick<T, K> => {
  const result = {} as { [K in keyof typeof obj]: (typeof obj)[K] };
  for (const key of keys) {
    result[key] = obj[key];
  }
  return result;
};

export const uniqueBy = <T, K extends keyof T>(arr: T[], key: K) => {
  const seen = new Set();
  return arr.filter((item) => {
    const value = item[key];
    if (seen.has(value)) {
      return false;
    }
    seen.add(value);
    return true;
  });
};

function rgbaToHex(rgba: string, forceRemoveAlpha = false) {
  return (
    '#' +
    rgba
      .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .filter((_, index) => !forceRemoveAlpha || index !== 3)
      .map((str) => parseFloat(str)) // Converts them to numbers
      .map((num, index) => (index === 3 ? Math.round(num * 255) : num)) // Converts alpha to 255 number
      .map((num) => num.toString(16)) // Converts numbers to hex
      .map((str) => (str.length === 1 ? '0' + str : str)) // Adds 0 when length of one number is 1
      .join('')
  ); // Puts the array to together to a string
}

// https://stackoverflow.com/a/51567564/1845423
export const colorIsDark = (color: string) => {
  let hex = color;
  if (color.startsWith('rgb')) {
    hex = rgbaToHex(color, true);
  }

  // If the color is a 3 digit hex, convert to 6 digit hex
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  const c_r = parseInt(hex.slice(0, 0 + 2), 16);
  const c_g = parseInt(hex.slice(2, 2 + 2), 16);
  const c_b = parseInt(hex.slice(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness < 156;
};

export const isExportCached = (file: DriveFile | undefined, cache: Cache | undefined) => {
  if (!cache || !file) return false;
  const id = file
    ? isADocument(file)
      ? isAShortcut(file)
        ? file.shortcutDetails.targetId
        : file.id
      : undefined
    : undefined;

  if (!id) return false;
  const data = cache.get(serialize([`/drive/v3/files/${id}/export`, id])?.[0])?.data as string;
  return Boolean(data);
};

export const getId = (file: DriveFile) => ('shortcutDetails' in file ? file.shortcutDetails.targetId : file.id);

export const exportFetcher = async (
  params: [url: string, id: string, mimeType: DriveAPI.DriveMimeType]
): Promise<string> => {
  const id = params[1];
  const mimeType = params[2];
  if (!id) return Promise.reject(new Error('No id provided'));
  if (!mimeType) return Promise.reject(new Error('No mimeType provided'));
  const { html } = await DriveAPI.exportFile({ id, mimeType });
  return html;
};

export const prefetch = (file: DriveFile, cache: Cache) => {
  // The cache is a Map<string, string> during the user session so we can use map methods
  assertType<Map<string, string>>(cache);
  const isCached = Boolean(cache.has(`/drive/v3/files/${getId(file)}/export`));
  if (isCached || !isExportable(file)) return;
  const id = file ? getId(file) : undefined;
  const mimeType = getMimeType(file);
  if (!id) throw new Error('No id provided');
  if (!mimeType) throw new Error('No mimeType provided');
  void preload(`/drive/v3/files/${id}/export`, async () => {
    const result = await DriveAPI.exportFile({ id, mimeType });
    return result.html;
  });
};

type Shortcut = Extract<DriveFile, { mimeType: DriveMimeTypes['shortcut'] }>;
export const isAShortcut = (file: DriveFile | undefined): file is Shortcut =>
  file ? file.mimeType === mimeTypes.shortcut : false;

export const isAWordDocument = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      (isAShortcut(file) &&
        file.shortcutDetails.targetMimeType ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    : false;

export const isAMarkDownFile = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'text/markdown' ||
      (isAShortcut(file) && file.shortcutDetails.targetMimeType === 'text/markdown')
    : false;

export const isAPDF = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/pdf' ||
      (isAShortcut(file) && file.shortcutDetails.targetMimeType === 'application/pdf')
    : false;

export const isADrawing = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/vnd.google-apps.drawing' ||
      (isAShortcut(file) && file.shortcutDetails.targetMimeType === 'application/vnd.google-apps.drawing')
    : false;

export const isAForm = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/vnd.google-apps.form' ||
      (isAShortcut(file) && file.shortcutDetails.targetMimeType === 'application/vnd.google-apps.form')
    : false;

type Document =
  | Extract<DriveFile, { mimeType: DriveMimeTypes['document'] }>
  | (Omit<Shortcut, 'shortcutDetails'> & {
      shortcutDetails: Omit<Shortcut['shortcutDetails'], 'targetMimeType'> & {
        targetMimeType: DriveMimeTypes['document'];
      };
    });

export const isADocument = (file: DriveFile | undefined): file is Document =>
  file
    ? file.mimeType === mimeTypes.document ||
      (isAShortcut(file) && file.shortcutDetails.targetMimeType === mimeTypes.document)
    : false;

export const isAPowerpoint = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/vnd.ms-powerpoint' ||
      file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      (isAShortcut(file) &&
        (file.shortcutDetails.targetMimeType === 'application/vnd.ms-powerpoint' ||
          file.shortcutDetails.targetMimeType ===
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
    : false;

export const isASlide = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/vnd.google-apps.presentation' ||
      (isAShortcut(file) && file.shortcutDetails.targetMimeType === 'application/vnd.google-apps.presentation')
    : false;

export const isASheet = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/vnd.google-apps.spreadsheet' ||
      file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      (isAShortcut(file) &&
        (file.shortcutDetails.targetMimeType === 'application/vnd.google-apps.spreadsheet' ||
          file.shortcutDetails.targetMimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))
    : false;

export const isAFolder = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === 'application/vnd.google-apps.folder' ||
      (isAShortcut(file) && file.shortcutDetails.targetMimeType === 'application/vnd.google-apps.folder')
    : false;

export const isAFile = (file: DriveFile) =>
  file.mimeType !== 'application/vnd.google-apps.folder' &&
  (!('shortcutDetails' in file) || file.shortcutDetails.targetMimeType !== 'application/vnd.google-apps.folder');

export function getSharedDriveId(files: DriveFile | DriveFile[]) {
  if (Array.isArray(files)) {
    const file = files.find((o) => 'driveId' in o);
    if (!file) return undefined;
    return 'driveId' in file ? file.driveId : undefined;
  }
  const file = files;
  if ('driveId' in file) {
    return file.driveId;
  }
  return undefined;
}

export const isExportable = (file: DriveFile | undefined) => {
  const mimeType = isAShortcut(file) ? file.shortcutDetails.targetMimeType : file?.mimeType;
  const fileType = Object.keys(mimeTypes).find((k) => mimeTypes[k as keyof typeof mimeTypes] === mimeType);
  return fileType ? fileType in exportFormats : false;
};

export const getMimeType = (file: DriveFile | undefined) =>
  isAShortcut(file) ? file.shortcutDetails.targetMimeType : file?.mimeType;

export const getMimeTypeNameFromFile = (file: DriveFile | undefined) => {
  const mimeType = getMimeType(file);
  const fileType = Object.keys(mimeTypes).find((k) => mimeTypes[k as keyof typeof mimeTypes] === mimeType);
  if (DEV) {
    if (!fileType) {
      log.info(file);
      throw new Error(`No mimeType found for ${mimeType}`);
    }
  }
  return fileType;
};

export const getMimeTypeName = (mimeType: DriveAPI.DriveMimeType | undefined) => {
  const fileType = Object.keys(mimeTypes).find((k) => mimeTypes[k as keyof typeof mimeTypes] === mimeType);
  if (DEV) {
    if (!fileType) {
      log.info(mimeType);
      throw new Error(`No mimeType found for ${mimeType}`);
    }
  }
  return fileType;
};

export const uuid = () => crypto.randomUUID();

export const getExportMimeType = (mimeType: DriveAPI.DriveMimeType) => {
  const fileType = Object.keys(mimeTypes).find((k) => mimeTypes[k as keyof typeof mimeTypes] === mimeType);
  if (!fileType) return undefined;
  const exportMimeType = exportFormats[fileType as keyof typeof exportFormats];
  return exportMimeType;
};

// https://gomakethings.com/preventing-cross-site-scripting-attacks-when-using-innerhtml-in-vanilla-javascript
export function sanitizeHTML(str: string) {
  const t = document.createElement('div');
  t.textContent = str;
  return t.innerHTML;
}

export const reg = {
  style: /<style[^>]*>[^<]*<\/style>/gi,
  br: /<br>/g,
  tags: /<[^>]*>?/g,
  nbsp: /&nbsp;/g,
  spaces: /\s+/g,
  lines: /\r?\n|\r/g,
  code: //g,
  // fontCheck: /@import url\((https:\/\/themes\.googleusercontent\.com\/fonts\/css\?kit=([\w-]+))\);/,
  // fontSize: /font-size:(\d+)/,
  // isURL,
  // isEmail: /^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  // https://regex101.com/r/7nfbOx/1

  // // https://regex101.com/r/u8jOdA/1
  // // We don't want to remove iframe's, video's, image's, etc.
  // isEmpty: /<\/?(html|head|meta|body|p|span)[^>]*>/g,
};

export const decodeHTML = (html: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const cleanHTML = (html: string, spaces = true) => {
  const result = html
    .replace(reg.style, '')
    .replace(reg.br, ' ')
    .replace(reg.tags, spaces ? ' ' : '')
    .replace(reg.nbsp, ' ')
    .replace(reg.lines, ' ')
    .replace(/&[a-z\d]{2,32};/gi, (entity) => entityMap[entity] ?? entity)
    .replace(/&#60418;/g, '')
    .replace(/&#60419;/g, '')
    .replace(/&#(\d+);/g, (_, code: string) => String.fromCodePoint(Number(code)))
    .replace(reg.spaces, ' ')

    // Remove dynamic tags {{ }}
    .replace(/\{\{files\}\}/g, '')
    .trim();
  return result;
};

const parser = isBun ? undefined : new DOMParser();

export const getTextFromFile = (file: DriveFile & { html?: string }, lowerCase = true): string => {
  if (!file.html) return '';
  if (file.mimeType === mimeTypes.pdf) return file.html?.toLowerCase() ?? '';
  if (isADocument(file) && isMarkdownLink(file.name)) return '';
  const html = getTextFromHTML(file.html, lowerCase);
  return html;
};

/**
 *
 * @param html the html string to parse
 * @param lowerCase whether to return the text in lowercase
 * @param spaces whether to replace tags with spaces (needed for search results)
 */
export const getTextFromHTML = (html: string, lowerCase = true, spaces = true): string => {
  if (!html) return '';

  // Clean the html, parse it, then return textContent
  // Accounts for &lt; and &gt; output instead of < and > from Docs
  // Provides the best support for unicode characters
  const cleanedHTML = cleanHTML(html, spaces);

  // In non-browser environments, return cleanedHTML
  if (!parser) return lowerCase ? cleanedHTML.toLowerCase() : cleanedHTML;

  // Check for remaining html entities or utf-8 characters
  // if not found return cleanedHTML
  const hasHTML = /&[a-z]{2,32};|&#\d{2,5};/i.test(cleanedHTML);
  if (!hasHTML) return lowerCase ? cleanedHTML.toLowerCase() : cleanedHTML;

  if (DEV) {
    // Print out all the entities
    const entities = cleanedHTML.match(/&[a-z]{2,32};|&#\d{2,5};/gi);
    log.info(Array.from(new Set(entities)));

    log.error('cleanHTML - Unhandled HTML entities:', Array.from(new Set(entities)));
    log.info(cleanedHTML);
  }
  const doc = parser.parseFromString(cleanedHTML, 'text/html');
  const text = doc.body.textContent;
  return (lowerCase ? text?.toLowerCase() : text) || '';
};

type Parameters = {
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  access_type?: 'online' | 'offline'; // Not included in docs, but required to get refresh token
  state?: string;
  include_granted_scopes?: string;
  login_hint?: string;
  prompt?: 'none' | 'consent' | 'select_account';
};

// Scopes
// https://developers.google.com/identity/protocols/oauth2/scopes

/// TODO: check the security of including origin in state
// https://github.com/googleapis/google-api-nodejs-client/issues/685#issuecomment-702619121
// https://stackoverflow.com/a/63409239/1845423
const login_hint = hasWindow ? gup('email') : false;
export const getAuthURL = (redirect: string) => {
  const paramsObj: Parameters = {
    client_id: '1060883813950-ku2t78clneeeqvhvv4d8lna6k28n3q23.apps.googleusercontent.com',
    redirect_uri: `${redirect}/api/auth/google/callback`,
    response_type: 'code',
    access_type: 'offline',
    include_granted_scopes: 'true',
    scope: defaultScope,

    // scope: 'email profile',
    // state: origin,
    prompt: window.location.search === '?prompt=consent' ? 'consent' : 'select_account',
    ...(login_hint && typeof login_hint === 'string' && { login_hint }),
  };
  const params = new URLSearchParams(paramsObj).toString();
  const authURL = `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
  return authURL;
};

type JSON = string | number | boolean | null | JSON[] | { [key: string]: JSON };
type JSONObject =
  | {
      [k: string]: JSON;
    }
  | JSON[];

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

// Small localStorage library for JSON objects
// Inspired by this API: https://www.npmjs.com/package/store
export const ls = {
  clear: () => {
    localStorage.clear();
  },
  assign: (key: string, data: JSONObject) => {
    const str = localStorage.getItem(key);
    const existingData = str ? (JSON.parse(str) as JSONObject) : undefined;
    const json = JSON.stringify({
      ...existingData,
      ...data,
    });
    localStorage.setItem(key, json);
  },
  set: <Data extends JSON>(key: string, data: Data) => {
    if (typeof data === 'string') {
      localStorage.setItem(key, data);
      return;
    }

    try {
      const json = JSON.stringify(data);
      localStorage.setItem(key, json);
    } catch {
      /* empty */
    }
  },
  get: <Data extends JSON>(key: string) => {
    let str: string | null;
    try {
      str = localStorage.getItem(key);
    } catch {
      return undefined;
    }

    if (str) {
      try {
        return JSON.parse(str) as Nullable<Data>;
      } catch {
        return str as unknown as Nullable<Data>;
      }
    }

    return undefined;
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};

export const getParentFolders = (files: DriveFile[], parentId: string): DriveFile[] => {
  const file = files.find(
    (o) =>
      o.id === parentId ||
      (o.mimeType === 'application/vnd.google-apps.shortcut' && o.shortcutDetails?.targetId === parentId)
  );
  if (!file) return [];
  return [file, ...getParentFolders(files, file.parents?.[0] ?? '')];
};

export const isInputEvent = <T>(e: KeyboardEvent | React.KeyboardEvent<T>) => {
  if (e.target instanceof Element && (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA')) {
    if (e.metaKey || e.ctrlKey || e.shiftKey) return true;
    return true;
  }
  if (e.target instanceof HTMLAnchorElement && e.target.classList.contains('MuiMenuItem-root')) {
    return true;
  }
  // Check for role menuitem
  if (e.target instanceof Element && e.target.getAttribute('role') === 'menuitem') {
    return true;
  }
  return false;
};

export const isModalEvent = <T>(e: KeyboardEvent | React.KeyboardEvent<T>) => {
  if (
    e.target instanceof Element &&
    (e.target.parentElement?.role === 'dialog' || e.target.closest('[role="dialog"]'))
  ) {
    return true;
  }
  return false;
};

export const macros = {
  files: /<p[^>]*><span[^>]*>\{\{files\}\}<\/span><\/p>|\{[^{]*\{files\}[^}]*\}/g,
  checkbox: /\{\{checkbox\}\}/g,
  checkedbox: /\{\{checkbox\}\}\{\{checked\}\}/g,
};

const partialMatchIndex = (content: string, input: string) => {
  const i = content.indexOf(input);
  const i2 = content.indexOf(input.slice(0, -1));
  const i3 = content.indexOf(input.slice(1));
  const index = i === -1 ? (i2 === -1 ? i3 : i2) : i;
  return { index, isPartialMatch: i === -1 };
};

export const getRange = ({ input, name, content }: { input: string; name: string; content: string }) => {
  // Title match
  const nameLower = name.toLowerCase();
  const inputLower = input.toLowerCase();
  const contentLower = content.toLowerCase();
  const nameIndex = nameLower.indexOf(inputLower);
  const title = name.slice(nameIndex, nameIndex + input.length);
  const titleBefore = name.slice(0, nameIndex);
  const titleAfter = name.slice(nameIndex + input.length);

  // Content match
  const { index: contentIndex, isPartialMatch } = partialMatchIndex(contentLower, inputLower);
  const range = 21;
  const match = content.slice(contentIndex, contentIndex + input.length + (isPartialMatch ? -1 : 0));
  const beforeIndex =
    contentIndex + range > content.length
      ? contentIndex - range * 2
      : contentIndex - range < 0
        ? 0
        : contentIndex - range;
  const textBefore = content.slice(beforeIndex, contentIndex);
  const textAfter = content.slice(contentIndex + input.length + (isPartialMatch ? -1 : 0), contentIndex + range);

  return {
    title,
    titleBefore,
    titleAfter,
    nameIndex,
    textBefore,
    match,
    textAfter,
  };
};

export const toLastModifiedTime = (date: string) => {
  const diff = Date.now() - new Date(date).getTime();
  const diffYears = Math.floor(diff / (1000 * 3600 * 24 * 365));

  // Early return for years
  if (diffYears >= 1) {
    return new Date(Date.now() - diff).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
  // if (diffYears > 0) {
  //   return `${diffYears} year${diffYears === 1 ? '' : 's'} ago`;
  // }

  const diffMonths = Math.floor(diff / (1000 * 3600 * 24 * 30));
  const diffDays = Math.floor(diff / (1000 * 3600 * 24));
  const diffHours = Math.floor(diff / (1000 * 3600));
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffSeconds = Math.floor(diff / 1000);
  if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths === 1 ? '' : 's'} ago`;
  }
  if (diffDays > 0) {
    return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
  }
  if (diffHours > 0) {
    return `${diffHours} hour${diffHours === 1 ? '' : 's'} ago`;
  }
  if (diffMinutes > 0) {
    return `${diffMinutes} minute${diffMinutes === 1 ? '' : 's'} ago`;
  }
  if (diffSeconds > 30) {
    return 'less than a minute ago';
  }
  return 'just now';
};

export const toTitleCase = (str: string) => {
  if (!str) return '';
  // If it's a single word with a period, replace with a space
  if (!str.includes(' ') && str.includes('.')) {
    return str
      .split('.')
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');
  }
  return str
    .split(' ')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ');
};

export const readingTime = (text: string) => {
  // https://scholarwithin.com/average-reading-speed#adult-average-reading-speed
  const wpm = 238;
  const noOfWords = text.trim().split(/\s+/).length;
  const minutes = noOfWords / wpm;
  const readTime = Math.ceil(minutes);

  if (readTime === 60) {
    return '1 hour read';
  }
  if (readTime > 60) {
    const hours = Math.floor(readTime / 60);
    const minutes = readTime % 60;
    return `${hours} hour${hours > 1 ? 's' : ''}, ${minutes} min read`;
  }

  return readTime + ' min read';
};

/**
 *
 * @param url https://lh3.googleusercontent.com/a/ACg8ocIcIn35jglKWYnlFYscQtH_2hoMv_yznpQjNoI2IkZFKMi3QzdC=s64
 * @param size
 */
export const resizePhoto = (url: string | undefined, size: number) => {
  if (!url) return '';
  return url.replace(/=s\d+/, `=s${size}`);
};

/**
 * It's possible for a user to have both auth/drive.file and drive scopes at the same time
 */
export const isFileScope = (scope?: string) => {
  const s = scope ?? gapi.client.getToken()?.scope;
  return s?.includes('drive.file') && !/drive\s/.test(s) && !s.endsWith('drive');
};

// export const request = async <T>(url: string, options: RequestInit): Promise<T> => {
//   const res = await fetch(url, options);
//   if (!res.ok) {
//     throw new Error(res.statusText);
//   }
//   return res.json();
// }

export const getNewFile = ({
  mimeType,
  name,
  parents,
  user,
  files,
}: {
  id?: string;
  name: string;
  mimeType: DriveFile['mimeType'];
  parents: [string];
  user: Profile | undefined;
  files: DriveFile[];
}): DriveFile => {
  if (mimeType === mimeTypes.shortcut) {
    throw new Error('TODO: add shortcut support');
  }
  const id = files.some((o) => o.id === newFileId) ? `${newFileId}-${uuid()}` : newFileId;
  const newFile = {
    id,
    name,
    ...(parents && { parents }),
    explicitlyTrashed: false,
    mimeType,
    createdTime: new Date().toISOString(),
    modifiedTime: new Date().toISOString(),
    ...(user && {
      lastModifyingUser: {
        displayName: user.name,
        photoLink: user.image,
      },
      owners: [
        {
          displayName: user.name,
          photoLink: user.image,
          emailAddress: user.email,
        },
      ],
    }),
    webViewLink: '',
    webContentLink: '',
    ownedByMe: true,
    size: 0,
  } satisfies DriveFile;
  return newFile;
};

export const safeParse = <T = unknown>(str: string) => {
  try {
    return JSON.parse(str) as T;
  } catch {
    return undefined;
  }
};

export const round = (num: number, precision = 0) => {
  const factor = 10 ** precision;
  return Math.round(num * factor) / factor;
};

export const strip = (arr: TemplateStringsArray, ...exprs: string[]) => {
  let str = '';
  for (let i = 0; i < arr.length; i++) {
    str += arr[i];
    if (i < exprs.length) {
      str += exprs[i];
    }
  }
  return str.trim().replace(/\n\s*/g, '');
};
