import cx from 'classnames';
import * as pageStyles from '~/components/Page/Page.css';
import { type DriveFile } from '~/utils/DriveAPI';
import { parseExport } from '~/utils/parseExport';
import { getMimeTypeNameFromFile, macros } from '~/utils/utils';

export const Page = ({
  content,
  file,
  pageRef,
  indexPage,
}: {
  content: string;
  file?: DriveFile | undefined;
  pageRef?: React.RefObject<HTMLDivElement> | undefined;
  indexPage?: DriveFile | undefined;
}) => {
  return (
    <div
      ref={pageRef}
      className={cx(pageStyles.page, getMimeTypeNameFromFile(file))}
      // style={{
      //   maxWidth: docWidth,
      // }}
      dangerouslySetInnerHTML={{
        // macros
        __html: (indexPage ? content.replace(macros.files, /* html */ `<div data-react="Tree"></div>`) : content)
          .replace(
            macros.checkedbox,
            /* html */ `<span data-react="Checkbox" data-props='${JSON.stringify({ checked: true })}'></span>`
          )
          .replace(macros.checkbox, /* html */ `<span data-react="Checkbox"></span>`),
      }}
    />
  );
};

/**
 * Used for testing with playwright
 */
export const PageSpec = ({ html }: { html: string }) => {
  const { content } = parseExport({ html });
  return <Page content={content} />;
};
