import { Button } from '@mui/base/Button';
import {
  Add,
  AddLinkOutlined,
  AddToDrive,
  ArrowDownward,
  ArrowUpward,
  CreateNewFolderOutlined,
  DriveFileRenameOutline,
  ExpandMore,
  MoreHoriz,
  PersonAddAlt1Outlined,
  Replay,
  SettingsOutlined,
  SubdirectoryArrowRight,
  VerticalAlignBottom,
  VerticalAlignTop,
} from '@mui/icons-material';
import { Collapse, Divider, Menu, MenuItem, Modal } from '@mui/material';
import cx from 'classnames';
import { bindMenu, usePopupState, type PopupState } from 'material-ui-popup-state/hooks';
import { useEffect, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { Link, useLocation } from 'wouter';
import { alignCenter, bold, fadeInDelay, flex } from '~/app.css';
import Icon from '~/elements/Icon/Icon';
import { shortcutBase } from '~/elements/Icon/Icon.css';
// import PoweredBy from '~/elements/PoweredBy/PoweredBy';
import { DriveCopy, DriveMove, Shortcut, Trash } from '~/elements/icons';
import * as DriveAPI from '~/utils/DriveAPI';
import { type DriveFile, type DriveMimeType } from '~/utils/DriveAPI';
import { DrivePicker } from '~/utils/DrivePicker';
import { DEV, isBun, LEGACY, maxDepth, mimeTypes, newFileId } from '~/utils/constants';
import { concat } from '~/utils/fast';
import gup from '~/utils/gup';
import { useKeyPress } from '~/utils/hooks';
import { isMarkdownLink } from '~/utils/isMarkdownLink';
import { log } from '~/utils/logger';
import { matchPath } from '~/utils/make-matcher';
import { sort } from '~/utils/sort';
import { type Profile } from '~/utils/types';
import {
  assert,
  getId,
  getMimeTypeName,
  getNewFile,
  isADocument,
  isAFolder,
  isAShortcut,
  prefetch,
  rem,
  uuid,
} from '~/utils/utils';
import { NestedMenuItem } from './NestedMenuItem';
import * as styles from './Tree.css';
import { link } from './Tree.css';
import getAllAncestors from './getAllAncestors';
import getAllChildren from './getAllChildren';
import getMovedFiles from './getMovedFiles';

type MenuState = Record<string, boolean>;

export type Props = {
  wiki: DriveFile;
  files: DriveFile[];
  currentParentId?: string | undefined;
  currentFile?: DriveFile | undefined;
  currentDepth?: number;
  openByDefault?: boolean;
  depth?: number | undefined;
  enableMenu?: boolean;
  menuController?:
    | {
        menuState: MenuState | undefined; // persisted state of the tree open/close
        addMenuState: PopupState | undefined;
        moreMenuState: PopupState | undefined;
        openId: string | undefined;
        setOpenId: ((id: string | undefined) => void) | undefined;
        sessionMenu: Record<string, boolean | undefined> | undefined; // session state of the tree open/close
        setSessionMenu: ((menu: Record<string, boolean | undefined>) => void) | undefined;
      }
    | undefined;
  hasLoaded?: boolean | undefined;
};

export const StaticTree = ({
  wiki,
  files,
  currentParentId,
  currentFile,
  currentDepth = 0,
  openByDefault: defaultOpen = false,
  depth = maxDepth,
  // TODO: rename to isStatic and reverse variable
  enableMenu = false,
  menuController,
  hasLoaded,
}: Props) => {
  const { cache } = useSWRConfig();
  const {
    menuState = {},
    addMenuState,
    moreMenuState,
    openId,
    setOpenId,
    sessionMenu = {},
    setSessionMenu,
  } = menuController ?? {};

  // SWR hook for menu that either lives here or is passed down as prop
  const { mutate: mutateMenuState } = useSWR<MenuState>(`/api/menu/${wiki.id}`);

  const children = sort(
    files.filter((file) => {
      if (file.name === 'wiki.logo' && file.mimeType === 'image/png') return false;
      if (LEGACY) return true;
      return file.parents?.[0] === (currentParentId ?? wiki.id);
    })
  );
  if (depth === maxDepth && currentDepth >= depth) log.error('Tree: Max depth reached');
  const { fontColor } = wiki.properties ?? {};
  if (currentDepth >= depth) return null;

  return (
    <div style={{ marginLeft: currentDepth > 0 ? '.75rem' : undefined }}>
      {children.map((file) => {
        const current = currentFile?.id === file.id;
        const folderChildren = files.filter((child) => child.parents?.[0] === getId(file));
        const hasChildren = isAFolder(file) && folderChildren.some((child) => child.parents?.[0] === getId(file));
        const duplicate = isAShortcut(file) ? files.some((o) => o.id === getId(file)) : false;
        const externalLink = isADocument(file) ? isMarkdownLink(file.name) : undefined;
        const legacyParentId = LEGACY ? gup('p') : undefined;
        const legacyTo = LEGACY
          ? legacyParentId
            ? `/app/page/${file.id}?p=${legacyParentId}`
            : `/app/page/${file.id}`
          : '';

        // Folder
        if (hasChildren && !duplicate) {
          const isOpen = sessionMenu[file.id] ?? menuState[file.id] ?? defaultOpen;
          return (
            <div key={file.id} data-testid="Tree">
              <div style={{ margin: '.25rem 0' }} className={styles.linkContainer}>
                <ExpandMore
                  titleAccess="Click to expand more"
                  style={{
                    transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                    marginRight: '.25rem',
                    color: enableMenu ? fontColor ?? '#fff' : undefined,
                  }}
                  onClick={(e) => {
                    if (e.shiftKey && (e.metaKey || e.ctrlKey)) {
                      const folders = files.filter(isAFolder);
                      const newMenu = Object.fromEntries(folders.map((o) => [o.id, !isOpen]));
                      void mutateMenuState(newMenu, false);

                      // filter newMenu for values that are false and apply that to sessionMenu
                      const newSessionMenu = Object.fromEntries(
                        Object.keys(newMenu)
                          .filter((o) => !newMenu[o])
                          .map((o) => [o, undefined])
                      );
                      setSessionMenu?.({ ...sessionMenu, ...newSessionMenu });
                    } else if (e.shiftKey) {
                      const nestedChildren = concat([file], getAllChildren(files, file.id).filter(isAFolder));
                      const newMenu = Object.fromEntries(nestedChildren.map((file) => [file.id, !isOpen]));
                      const newMenuStatus = { ...menuState, ...newMenu };
                      void mutateMenuState(newMenuStatus, false);

                      const newSessionMenu = Object.fromEntries(Object.keys(newMenu).map((o) => [o, undefined]));
                      setSessionMenu?.({ ...sessionMenu, ...newSessionMenu });
                    } else {
                      const { id } = file;
                      const newMenu = { ...menuState, [id]: !isOpen };
                      void mutateMenuState(newMenu, false);

                      // if there are any values in sessionMenu that are true, that were previously not in menuStatus, set sessionMenu value to undefined
                      if (sessionMenu?.[id] && newMenu?.[id] === false) {
                        setSessionMenu?.({ ...sessionMenu, [id]: undefined });
                      }
                    }
                  }}
                />
                <Link
                  aria-current={current ? 'page' : undefined}
                  className={cx(
                    {
                      [bold]: current,
                    },
                    flex,
                    alignCenter
                  )}
                  style={{
                    // color: disableMenu ? undefined : '#fff',
                    textDecoration: 'none',
                    position: 'relative',
                  }}
                  to={LEGACY ? legacyTo : `/app/page/${wiki.id}/${file.id}`}
                >
                  {file.name}
                  {isAShortcut(file) && (
                    <>
                      <Shortcut
                        aria-hidden="true"
                        style={{
                          marginLeft: rem(6),
                        }}
                        className={shortcutBase}
                        viewBox="0 0 257 257"
                      />
                    </>
                  )}
                </Link>
                {enableMenu && (
                  <div className={styles.menuContainer} style={{ display: openId === file.id ? 'flex' : undefined }}>
                    <Button
                      data-icon="add"
                      onClick={(e) => {
                        if (!addMenuState) throw new Error('addMenuState is not defined');
                        setOpenId?.(file.id);
                        addMenuState.setAnchorEl(e.currentTarget);
                        addMenuState.open();
                      }}
                    >
                      <Add htmlColor={fontColor ?? ''} />
                    </Button>
                    <Button
                      data-icon="more"
                      onClick={(e) => {
                        if (!moreMenuState) throw new Error('moreMenuState is not defined');
                        setOpenId?.(file.id);
                        moreMenuState.setAnchorEl(e.currentTarget);
                        moreMenuState.open();
                      }}
                    >
                      <MoreHoriz htmlColor={fontColor ?? ''} />
                    </Button>
                  </div>
                )}
              </div>
              <Collapse in={isOpen} timeout={isBun ? 0 : 'auto'} unmountOnExit>
                <StaticTree
                  wiki={wiki}
                  files={files}
                  currentParentId={getId(file)}
                  currentFile={currentFile}
                  currentDepth={currentDepth + 1}
                  openByDefault={defaultOpen}
                  depth={depth}
                  menuController={{
                    menuState,
                    addMenuState,
                    moreMenuState,
                    openId,
                    setOpenId,
                    sessionMenu,
                    setSessionMenu,
                  }}
                  enableMenu={enableMenu}
                  hasLoaded={hasLoaded}
                />
              </Collapse>
            </div>
          );
        }

        return (
          <div key={file.id} className={styles.linkContainer} onMouseDown={() => prefetch(file, cache)}>
            <Link
              aria-current={current ? 'page' : undefined}
              className={cx({
                [link]: true,
                [bold]: current,
              })}
              style={{
                // color: disableMenu ? undefined : '#fff',
                textDecoration: 'none',
              }}
              to={externalLink ? externalLink.url : LEGACY ? legacyTo : `/app/page/${wiki.id}/${file.id}`}
              {...(externalLink?.url && {
                target: '_blank',
              })}
              {...(LEGACY && {
                onClick: () => {
                  window.legacyHistory?.push(externalLink ? externalLink.url : legacyTo);
                },
              })}
            >
              <Icon
                className={cx(
                  {
                    [fadeInDelay]: enableMenu && !hasLoaded && isAFolder(file) && depth > 1,
                  },
                  styles.icon
                )}
                file={file}
                color={fontColor}
                externalLink={externalLink}
                sidebar={enableMenu}
              />
              <span className={styles.textContainer}>
                <span
                  className={styles.text}
                  style={{
                    color: enableMenu ? fontColor : undefined,
                  }}
                >
                  {externalLink ? externalLink.name : file.name}
                </span>
              </span>
            </Link>
            {menuController && (
              <div className={styles.menuContainer} style={{ display: openId === file.id ? 'flex' : undefined }}>
                <Button
                  data-icon="add"
                  onClick={(e) => {
                    if (!addMenuState) throw new Error('addMenuState is not defined');
                    setOpenId?.(file.id);
                    addMenuState.setAnchorEl(e.currentTarget);
                    addMenuState.open();
                  }}
                >
                  <Add htmlColor={fontColor ?? ''} titleAccess="add" />
                </Button>
                <Button
                  data-icon="more"
                  onClick={(e) => {
                    if (!moreMenuState) throw new Error('moreMenuState is not defined');
                    setOpenId?.(file.id);
                    moreMenuState.setAnchorEl(e.currentTarget);
                    moreMenuState.open();
                  }}
                >
                  <MoreHoriz htmlColor={fontColor ?? ''} />
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

type PageModalType = 'document' | 'sheet' | 'slide' | 'form' | 'folder';
type ModalType = 'rename' | 'share' | 'trash' | 'copy' | 'link' | 'shortcut' | PageModalType;
const pageModalTypes = ['document', 'sheet', 'slide', 'form', 'folder'] as const satisfies PageModalType[];
const pageTypes = {
  document: 'application/vnd.google-apps.document',
  sheet: 'application/vnd.google-apps.spreadsheet',
  slide: 'application/vnd.google-apps.presentation',
  form: 'application/vnd.google-apps.form',
  folder: 'application/vnd.google-apps.folder',
} as const satisfies Record<PageModalType, DriveMimeType>;

const Tree = (props: Omit<Props, 'enableMenu'> & { showHome?: boolean; user?: Profile | undefined }) => {
  const navigate = useLocation()[1];
  const { currentFile, files, wiki, showHome, user } = props;
  const { fontColor } = wiki.properties ?? {};
  // isBun ? () => fetch(`/api/menu/${wiki.id}`).then((res) => res.json()) : () => Promise.resolve({})
  const { data: menuState, mutate: mutateMenu } = useSWR<MenuState>(`/api/menu/${wiki.id}`, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  const [sessionMenu, setSessionMenu] = useState<Record<string, boolean | undefined>>({});
  const moreMenuState = usePopupState({ variant: 'popover', popupId: 'MoreMenu' });
  const addMenuState = usePopupState({ variant: 'popover', popupId: 'AddMenu' });
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const { mutate } = useSWRConfig();
  const { mutate: mutateFiles } = useSWR<DriveFile[]>(`/api/files/${wiki.id}`);
  const [modalStatus, setModalStatus] = useState<{
    type?: ModalType | undefined;
    open: boolean;
  }>();
  const [input, setInput] = useState('');
  const [linkInput, setLinkInput] = useState({
    name: '',
    url: '',
  });
  const [activeFile, setActiveFile] = useState<DriveFile | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const isWiki = currentFile?.id === wiki.id;
  const isWikiActive = activeId === wiki.id;
  const file = isWiki ?? isWikiActive ? wiki : files.find((o) => o.id === currentFile?.id);
  const isModalOpen = modalStatus?.open ?? false;
  const isMenuOpen = moreMenuState.isOpen || addMenuState.isOpen;
  const openFile = isWikiActive ? wiki : activeId ? files.find((o) => o.id === activeId) : undefined;
  const siblings = activeId ? files.filter((file) => file.parents?.[0] === openFile?.parents?.[0]) : [];
  const sortedSiblings = sort(siblings);
  const openIndex = sortedSiblings.findIndex((o) => o.id === activeId);
  const showMoveUp = openIndex > 0;
  const showMoveDown = openIndex < siblings.length - 1;
  const openId = activeId ?? activeFile?.id;
  const isMovingRef = useRef<Record<string, boolean | undefined>>({});
  const pendingMoveRef = useRef<Record<string, DriveFile | undefined>>({});
  const { mutate: mutateOpenFile } = useSWR<DriveFile>(openId ? `/drive/v3/files/${openId}` : null);
  const { trigger: moveFile } = useSWRMutation('/tmp/tree', async (_: string, { arg: file }: { arg: DriveFile }) => {
    const { id, properties } = file;
    void mutate(`/drive/v3/files/${id}`, file, false);

    // If there is a request in progress for this file, add it to pending
    if (isMovingRef.current[id]) {
      pendingMoveRef.current[id] = file;
      return;
    }

    // Make request
    const request = DriveAPI.update({ id, properties });
    isMovingRef.current[id] = true;
    const result = await request;
    isMovingRef.current[id] = undefined;

    // Once request completes, if there is a pending request for this file, move it
    if (pendingMoveRef.current[id]) {
      void moveFile(pendingMoveRef.current[id]);
      pendingMoveRef.current[id] = undefined;
    }
    return result;
  });

  const focusInput = () =>
    queueMicrotask(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    });

  useKeyPress(['ArrowUp', 'ArrowDown'], (e) => {
    if (!e.shiftKey || !e.ctrlKey) return;
    if (file?.id === wiki.id) return;
    if (isModalOpen || isMenuOpen) return;
    if (!file) throw new Error('File not found');
    const { nextFile, nextSiblingFile, nextFiles } = getMovedFiles(files, file, e.key === 'ArrowUp' ? 'up' : 'down');

    // Update current file
    void moveFile(nextFile);

    // Update sibling
    if (nextSiblingFile) {
      void moveFile(nextSiblingFile);
    }

    // Update tree
    void mutateFiles(nextFiles, false);
  });

  useKeyPress('.', () => {
    if (!file) return;
    if (isModalOpen || isMenuOpen) return;
    if (file.id === newFileId) {
      // todo: we need to show a loading screen in the menu
      // if(!pendingRequestRef.current) throw new Error('Pending request not found');
      // await pendingRequestRef.current
      throw new Error('Attempted to open a file with the id "new"');
    }
    setActiveId?.(file.id);

    const activeMenuItem = document.querySelector('[aria-current="page"]')?.parentElement;
    const moreIcon = activeMenuItem?.querySelector('[data-icon="more"]');
    if (!moreIcon) throw new Error('More icon not found');
    queueMicrotask(() => {
      moreMenuState.setAnchorEl(moreIcon);
      moreMenuState.open();
    });
  });

  useKeyPress(['a', '+'], () => {
    if (!file) return;
    if (isModalOpen || isMenuOpen) return;

    setActiveId?.(file.id);
    const activeMenuItem = document.querySelector('[aria-current="page"]')?.parentElement;
    const addIcon = activeMenuItem?.querySelector('[data-icon="add"]');
    if (!addIcon) throw new Error('Add icon not found');
    queueMicrotask(() => {
      addMenuState.setAnchorEl(addIcon);
      addMenuState.open();
    });
  });

  useKeyPress(' ', (e) => {
    if (!file) return;
    if (isModalOpen || isMenuOpen) return;
    if (!isAFolder(file)) return;
    const hasChildren = files.some((o) => o.parents?.[0] === getId(file));
    if (!hasChildren) return;

    e.preventDefault();
    const id = file.id;
    const nextValue = !(sessionMenu[id] ?? menuState?.[id]);
    const newSessionMenu = { ...sessionMenu, [id]: nextValue };
    setSessionMenu(newSessionMenu);

    const newSavedMenu = { ...menuState, [id]: nextValue };
    void mutateMenu(newSavedMenu, false);
  });

  // Determines whether a files ancestors should be open
  const parent = file?.parents?.[0];
  useEffect(() => {
    const ancestors = parent ? getAllAncestors(files, parent) : [];
    const newMenu = Object.fromEntries(ancestors.map((o) => [o.id, true]));
    setSessionMenu((sessionMenu) => ({ ...sessionMenu, ...newMenu }));
  }, [parent, files]);

  // Opens folder by default when navigating to it
  // const savedMenuState = file ? menuState?.[file?.id] ?? sessionMenu[file?.id] : undefined;
  // useEffect(() => {
  //   if (!file || file.id !== currentFile?.id) return;
  //   if (savedMenuState !== undefined) return;
  //   setSessionMenu((sessionMenu) => ({ ...sessionMenu, [file.id]: true }));
  // }, [file, currentFile, savedMenuState]);

  // Uncomment to disable remembering menu state in the tree
  // useEffect(() => void mutateMenu(undefined, false), [mutateMenu]);

  const closeModal = () => {
    setModalStatus({ open: false, type: undefined });
    setInput('');
    setLinkInput({ name: '', url: '' });
    setActiveFile(undefined);
  };

  const closeMenu = () => {
    setActiveId(undefined);
    moreMenuState.close();
    addMenuState.close();
  };

  const openAddMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    addMenuState.setAnchorEl(e.currentTarget);
    addMenuState.open();
  };

  const openMoreMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    moreMenuState.setAnchorEl(e.currentTarget);
    moreMenuState.open();
  };

  const openModal = (type: ModalType) => {
    setModalStatus({
      open: true,
      type,
    });
    if (!openFile) throw new Error('File not found');
    setActiveId(undefined);
    const isPageModal = pageModalTypes.includes(type as PageModalType);
    if (!isPageModal) {
      setInput(openFile.name);
    }
    if (type === 'share' && !openFile.permissions) {
      void (async () => {
        const permissions = await DriveAPI.permissions.list({ id: openFile.id });
        setActiveFile({ ...openFile, permissions });
      })();
    }
    setActiveFile(openFile);
    focusInput();
    closeMenu();
    if (isBun) return;
  };

  const isPageModal = modalStatus?.type && pageModalTypes.includes(modalStatus.type as PageModalType);
  return (
    <>
      {showHome && (
        <div className={styles.linkContainer}>
          <Link
            className={cx({
              [bold]: isWiki,
            })}
            to={`/app/page/${wiki.id}/${wiki.id}`}
            {...(isWiki && { 'aria-current': 'page' })}
          >
            {wiki?.name}
          </Link>
          <div
            className={styles.menuContainer}
            style={{ display: activeId && activeId === wiki?.id ? 'flex' : undefined }}
          >
            <Button
              data-icon="add"
              onClick={(e) => {
                setActiveId(wiki.id);
                openAddMenu(e);
              }}
            >
              <Add htmlColor={fontColor ?? ''} />
            </Button>
            <Button
              data-icon="more"
              onClick={(e) => {
                setActiveId(wiki.id);
                openMoreMenu(e);
              }}
            >
              <MoreHoriz htmlColor={fontColor ?? ''} />
            </Button>
          </div>
        </div>
      )}
      <StaticTree
        {...props}
        enableMenu
        menuController={{
          menuState,
          addMenuState,
          moreMenuState,
          // FIXME: rename these 2 to activeId
          // There was meant to be a difference between "active" and "open", open is when it is open in the modal, active is when the menu is active
          openId: activeId,
          setOpenId: setActiveId,
          sessionMenu,
          setSessionMenu,
        }}
      />
      {/* TODO:
        - Only show for public wikis
       */}
      {/* <PoweredBy /> */}
      <Menu
        {...bindMenu(moreMenuState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transitionDuration={{
          enter: isBun ? 0 : 250,
          exit: 0,
        }}
        onClose={() => {
          setActiveId(undefined);
          moreMenuState.close();
        }}
      >
        <MenuItem className={styles.menuItem} onClick={() => openModal('rename')}>
          <DriveFileRenameOutline className={styles.menuIcon} style={{ fontSize: 20 }} />
          Rename
        </MenuItem>
        {!isWikiActive && (
          <NestedMenuItem
            className={styles.menuItem}
            parentMenuOpen={moreMenuState.isOpen}
            MenuProps={{
              transitionDuration: {
                enter: isBun ? 0 : 250,
                exit: 0,
              },
            }}
            label="Move"
            leftIcon={<SubdirectoryArrowRight className={styles.menuIcon} style={{ fontSize: 20 }} />}
            onClick={async () => {
              await DrivePicker.load();
              DrivePicker.generatePicker({ type: 'folder', wiki, onAction: (_data) => {} });
              DrivePicker.open('folder');
              closeMenu();
            }}
          >
            {showMoveUp && (
              <MenuItem
                onClick={() => {
                  const file = props.files.find((o) => o.id === activeId);
                  if (!file) throw new Error('File not found');
                  const { nextFile, nextSiblingFile, nextFiles } = getMovedFiles(props.files, file, 'up');
                  void moveFile(nextFile);
                  if (nextSiblingFile) void moveFile(nextSiblingFile);
                  void mutateFiles(nextFiles, false);
                  closeMenu();
                }}
              >
                <ArrowUpward className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move up
              </MenuItem>
            )}
            {showMoveDown && (
              <MenuItem
                onClick={() => {
                  const file = props.files.find((o) => o.id === activeId);
                  if (!file) throw new Error('File not found');
                  const { nextFile, nextSiblingFile, nextFiles } = getMovedFiles(props.files, file, 'down');
                  void moveFile(nextFile);
                  if (nextSiblingFile) void moveFile(nextSiblingFile);
                  void mutateFiles(nextFiles, false);
                  closeMenu();
                }}
              >
                <ArrowDownward className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move down
              </MenuItem>
            )}
            <MenuItem
              onClick={async () => {
                await DrivePicker.load();
                DrivePicker.generatePicker({ type: 'folder', wiki, onAction: (_data) => {} });
                DrivePicker.open('folder');
                closeMenu();
              }}
            >
              <DriveMove aria-hidden className={styles.menuIcon} style={{ fontSize: 22 }} />
              Move to
            </MenuItem>
            {siblings.length > 1 && <Divider />}
            {showMoveUp && (
              <MenuItem
                onClick={() => {
                  if (!openFile) throw new Error('File not found');
                  const { nextFile, nextSiblingFile, nextFiles } = getMovedFiles(props.files, openFile, 'top');
                  void moveFile(nextFile);
                  if (nextSiblingFile) void moveFile(nextSiblingFile);
                  void mutateFiles(nextFiles, false);
                  closeMenu();
                }}
              >
                <VerticalAlignTop className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move to top
              </MenuItem>
            )}
            {showMoveDown && (
              <MenuItem
                onClick={() => {
                  if (!openFile) throw new Error('File not found');
                  const { nextFile, nextSiblingFile, nextFiles } = getMovedFiles(props.files, openFile, 'bottom');
                  void moveFile(nextFile);
                  if (nextSiblingFile) void moveFile(nextSiblingFile);
                  void mutateFiles(nextFiles, false);
                  closeMenu();
                }}
              >
                <VerticalAlignBottom className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move to bottom
              </MenuItem>
            )}
            {siblings.length > 1 && openFile?.properties?.index && (
              <MenuItem
                onClick={() => {
                  if (!openFile) throw new Error('File not found');
                  if (!openFile.properties) return;
                  const nextProperties = {
                    ...openFile.properties,
                    index: null,
                  };
                  void moveFile({ ...openFile, properties: nextProperties });

                  const nextFiles = files.map((o) => {
                    if (o.id === activeId) return { ...o, properties: nextProperties };
                    return o;
                  });
                  void mutateFiles(nextFiles, false);
                  closeMenu();
                }}
              >
                <Replay className={styles.menuIcon} style={{ fontSize: 22 }} />
                Reset position
              </MenuItem>
            )}
          </NestedMenuItem>
        )}
        {!isWikiActive && (
          <MenuItem className={styles.menuItem} onClick={() => openModal('copy')}>
            <DriveCopy className={styles.menuIcon} style={{ fontSize: 20 }} />
            Make a copy
          </MenuItem>
        )}
        <MenuItem
          className={styles.menuItem}
          onClick={() => {
            openModal('share');
          }}
        >
          <PersonAddAlt1Outlined className={styles.menuIcon} style={{ fontSize: 20 }} />
          Share
        </MenuItem>
        {!isWikiActive && (
          <MenuItem className={styles.menuItem} onClick={() => openModal('trash')}>
            <Trash aria-hidden className={styles.menuIcon} style={{ fontSize: 20 }} />
            Trash
          </MenuItem>
        )}
        {isWikiActive && (
          <MenuItem className={styles.menuItem} component={Link} to="/app/settings" onClick={() => closeMenu()}>
            <SettingsOutlined className={styles.menuIcon} style={{ fontSize: 20 }} />
            Settings
          </MenuItem>
        )}
      </Menu>
      <Menu
        {...bindMenu(addMenuState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transitionDuration={{
          enter: isBun ? 0 : 250,
          exit: 0,
        }}
        onClose={() => {
          setActiveId(undefined);
          addMenuState.close();
        }}
      >
        <MenuItem className={styles.menuItem} onClick={() => openModal('document')}>
          <img
            {...(DEV && {
              referrerPolicy: 'no-referrer',
            })}
            src="https://www.gstatic.com/images/branding/product/1x/docs_48dp.png"
            className={styles.menuImage}
            alt="Document"
            aria-hidden="true"
          />
          Document
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('sheet')}>
          <img
            className={styles.menuImage}
            src="https://www.gstatic.com/images/branding/product/1x/sheets_48dp.png"
            alt="Sheet"
            aria-hidden="true"
          />
          Sheet
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('slide')}>
          <img
            className={styles.menuImage}
            src="https://www.gstatic.com/images/branding/product/1x/slides_48dp.png"
            alt="Slide"
            aria-hidden="true"
          />
          Slide
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('form')}>
          <img
            className={styles.menuImage}
            src="https://www.gstatic.com/images/branding/product/1x/forms_48dp.png"
            alt="Form"
            aria-hidden="true"
          />
          Form
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('folder')}>
          <CreateNewFolderOutlined className={styles.menuIcon} style={{ fontSize: 22 }} />
          Folder
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('link')}>
          <AddLinkOutlined className={styles.menuIcon} style={{ fontSize: 24 }} />
          Link
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={async () => {
            // open picker
            await DrivePicker.load();
            DrivePicker.generatePicker({ type: 'file', wiki, onAction: (_data) => {} });
            DrivePicker.open('file');
            closeMenu();
          }}
        >
          <AddToDrive style={{ fontSize: 22 }} className={styles.menuIcon} />
          Shortcut
        </MenuItem>
      </Menu>
      <Modal open={isModalOpen} onClose={closeModal} role="dialog">
        <form
          style={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'var(--root-bg-color)',
            padding: '2rem',
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px',
            outline: 'none',
            borderRadius: 10,
          }}
          onKeyDown={(e) => {
            // Submit form on enter if there are no inputs
            if (e.key === 'Enter' && e.target instanceof HTMLFormElement) {
              e.preventDefault();
              e.stopPropagation();
              e.currentTarget.dispatchEvent(new Event('submit', { bubbles: true }));
            }
          }}
          onSubmit={(e) => {
            e.preventDefault();
            if (!activeFile) throw new Error('File not found');
            const type = modalStatus?.type;
            if (!type) throw new Error('Modal type not found');
            if (type === 'rename') {
              if (!input) throw new Error('Input is empty');
              void mutateOpenFile(DriveAPI.rename({ id: activeFile.id, name: input }), {
                optimisticData: {
                  ...activeFile,
                  name: input,
                  modifiedTime: new Date().toISOString(),
                },
                populateCache: true,
                revalidate: false,
              });
              void mutateFiles(
                props.files.map((o) => {
                  if (o.id === activeFile.id) return { ...o, name: input };
                  return o;
                }),
                {
                  populateCache: true,
                  revalidate: false,
                }
              );
            }
            if (type === 'trash') {
              void mutateOpenFile(DriveAPI.trash({ id: activeFile.id }), {
                optimisticData: {
                  ...activeFile,
                  explicitlyTrashed: true,
                  modifiedTime: new Date().toISOString(),
                },
                populateCache: true,
                revalidate: false,
              });
              void mutateFiles(
                props.files.filter((o) => o.id !== activeFile.id),
                {
                  populateCache: true,
                  revalidate: false,
                }
              );
              const siblingIndex = sortedSiblings.findIndex((o) => o.id === activeFile.id);
              const prevSibling = sortedSiblings[siblingIndex - 1];
              if (prevSibling) {
                navigate(`/app/page/${wiki.id}/${prevSibling.id}`);
              } else {
                navigate(`/app/page/${wiki.id}/${activeFile.parents?.[0] ?? wiki.id}`);
              }
            }
            if (type === 'link') {
              // create link
              const parents = isAFolder(activeFile) ? ([activeFile.id] satisfies [string]) : activeFile.parents;
              if (!parents) throw new Error('Parents not found');
              const name = linkInput.name.trim() || linkInput.url;
              const newFile = getNewFile({
                name: `[${name}](${linkInput.url})`,
                mimeType: mimeTypes.document,
                parents,
                user,
                files,
              });

              void mutateFiles(
                DriveAPI.create({ name, mimeType: mimeTypes.document, parents }).then((res) => {
                  // If the temp id is still in use, navigate to the new page
                  const { params } = matchPath('/app/page/:wiki/:child');
                  if (params?.child === newFileId) {
                    void (async () => {
                      await mutate(`/drive/v3/files/${res.id}/export`, '');
                      navigate(`/app/page/${wiki.id}/${res.id}`, {
                        replace: true,
                      });
                    })();
                  }
                  return [...files, res];
                }),
                {
                  revalidate: false,
                  populateCache: true,
                  optimisticData: [...files, newFile],
                }
              );
              navigate(`/app/page/${wiki.id}/${newFile.id}`);
            }

            if (type === 'copy') {
              const name = `Copy of ${activeFile.name}`;
              const id = uuid();
              void mutateFiles([
                ...files,
                {
                  ...activeFile,
                  id,
                  name,
                  createdTime: new Date().toISOString(),
                  modifiedTime: new Date().toISOString(),
                },
              ]);
              navigate(`/app/page/${wiki.id}/${id}`);
            }
            if (isPageModal) {
              const parents = isAFolder(activeFile) ? ([getId(activeFile)] satisfies [string]) : activeFile.parents;
              if (!parents) throw new Error('Parents not found');
              assert<PageModalType>(modalStatus.type, 'Modal type not found');
              const mimeType = pageTypes[modalStatus.type];
              assert(mimeType, 'Mime type not found');
              const formattedInput = input.trim();
              const name = formattedInput.length > 0 ? formattedInput : `Untitled ${getMimeTypeName(mimeType)}`;

              const newFile = getNewFile({
                name,
                mimeType,
                parents,
                user,
                files,
              });

              void mutateFiles(
                DriveAPI.create({ name, mimeType, parents }).then((res) => {
                  // If the temp id is still in use, navigate to the new page
                  const { params } = matchPath('/app/page/:wiki/:child');
                  if (params?.child === newFileId) {
                    void (async () => {
                      await mutate(`/drive/v3/files/${res.id}/export`, '');
                      navigate(`/app/page/${wiki.id}/${res.id}`, {
                        replace: true,
                      });
                    })();
                  }
                  return [...files, res];
                }),
                {
                  revalidate: false,
                  populateCache: true,
                  optimisticData: [...files, newFile],
                }
              );

              // navigate to new page
              navigate(`/app/page/${wiki.id}/${newFileId}`);
            }
            void closeModal();
          }}
        >
          {modalStatus?.type === 'rename' && (
            <>
              <h2
                style={{
                  marginTop: 0,
                  fontWeight: 500,
                  fontSize: 20,
                }}
                id="modal-title"
              >
                Rename
              </h2>
              <input
                aria-labelledby="modal-title"
                ref={inputRef}
                // className={styles.input}
                value={input ?? ''}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                style={{
                  border: '1px solid #cfcfcf',
                }}
              />
              <div
                style={{
                  paddingTop: 34,
                  textAlign: 'right',
                }}
              >
                <Button
                  style={{
                    marginRight: 6,
                    color: '#5f6368',
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="button">Save</Button>
              </div>
            </>
          )}
          {modalStatus?.type === 'trash' && (
            <>
              <h2
                style={{
                  marginTop: 0,
                  fontWeight: 500,
                  fontSize: 20,
                }}
                id="modal-title"
              >
                Move to trash
              </h2>
              <p>Are you sure you want to move this item to trash?</p>
              <div
                style={{
                  paddingTop: 34,
                  textAlign: 'right',
                }}
              >
                <Button
                  style={{
                    marginRight: 6,
                    color: '#5f6368',
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit">Move to trash</Button>
              </div>
            </>
          )}
          {modalStatus?.type === 'copy' && (
            <>
              <h2
                style={{
                  marginTop: 0,
                  fontWeight: 500,
                  fontSize: 20,
                }}
                id="modal-title"
              >
                Make a copy
              </h2>
              <p>Are you sure you want to make a copy of this item?</p>
              <div
                style={{
                  paddingTop: 34,
                  textAlign: 'right',
                }}
              >
                <Button
                  style={{
                    marginRight: 6,
                    color: '#5f6368',
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit">Make a copy</Button>
              </div>
            </>
          )}
          {modalStatus?.type === 'share' && (
            <>
              <h2
                style={{
                  marginTop: 0,
                  fontWeight: 500,
                  fontSize: 20,
                }}
                id="modal-title"
              >
                Share
              </h2>
              <input
                readOnly
                value="http://localhost:3000/app/page/1/2"
                style={{ width: '100%', border: '1px solid #cfcfcf' }}
              />
              <h4>WHO HAS ACCESS</h4>
              {activeFile?.permissions?.map((permission) => (
                <div key={permission.id}>
                  {permission.type === 'user' && (
                    <>
                      <div>{permission.displayName}</div>
                      <div>{permission.emailAddress}</div>
                    </>
                  )}
                </div>
              ))}
              <div
                style={{
                  paddingTop: 34,
                  textAlign: 'right',
                }}
              >
                <Button
                  style={{
                    marginRight: 6,
                    color: '#5f6368',
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit">Share</Button>
              </div>
            </>
          )}
          {modalStatus?.type === 'link' && (
            <>
              <h2
                style={{
                  marginTop: 0,
                  fontWeight: 500,
                  fontSize: 20,
                }}
                id="modal-title"
              >
                New link
              </h2>
              <label id="link-name" htmlFor="link-name">
                Name <small>(optional)</small>
              </label>
              <br />
              <input
                aria-labelledby="link-name"
                ref={inputRef}
                onChange={(e) => {
                  setLinkInput((prev) => ({ ...prev, name: e.target.value }));
                }}
                style={{
                  border: '1px solid #cfcfcf',
                }}
              />
              <br />
              <br />
              <label id="link-url" htmlFor="link-url">
                Web address
              </label>
              <br />
              <input
                aria-labelledby="link-url"
                placeholder="example.com"
                onChange={(e) => {
                  setLinkInput((prev) => ({ ...prev, url: e.target.value }));
                }}
                style={{
                  border: '1px solid #cfcfcf',
                }}
              />
              <div
                style={{
                  paddingTop: 34,
                  textAlign: 'right',
                }}
              >
                <Button
                  style={{
                    marginRight: 6,
                    color: '#5f6368',
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </div>
            </>
          )}
          {isPageModal && (
            <>
              <h2
                style={{
                  marginTop: 0,
                  fontWeight: 500,
                  fontSize: 20,
                }}
                id="modal-title"
              >
                New {modalStatus.type}
              </h2>
              <input
                aria-labelledby="modal-title"
                ref={inputRef}
                // className={styles.input}
                defaultValue={'Untitled document'}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                style={{
                  border: '1px solid #cfcfcf',
                }}
              />
              <div
                style={{
                  paddingTop: 34,
                  textAlign: 'right',
                }}
              >
                <Button
                  style={{
                    marginRight: 6,
                    color: '#5f6368',
                  }}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </div>
            </>
          )}
        </form>
      </Modal>
    </>
  );
};

export default Tree;
